import React, { useEffect, useState } from 'react';
import FileManagerWrapper from './FileManagerWrapper';
import axios from 'axios';
import './App.css';
import { CKEditor } from 'ckeditor4-react';


const hostname = process.env.REACT_APP_API_HOST_URL;

const App = () => {
  const [isFocus, setIsFocus] = useState(0)
  const [data, setData] = useState("");
  const [filenName, setFileName] = useState("")
  const [openFolderMap, setOpenFolderMap] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [map, setMap] = useState(null);
  let htmlData = '';
  useEffect(() => {
    if (document.getElementsByClassName('cke_editable').length && document.getElementsByTagName('li').length) {
      let editorWidth = document.getElementsByClassName('cke_editable')[0].clientWidth;
      let totalLength = document.getElementsByTagName('li').length;
      let lastElementWidth = document.getElementsByTagName('li')[totalLength - 1].clientWidth;
      if (lastElementWidth < 100) {
        document.getElementsByClassName('cke_editable')[0].style.width = (editorWidth + 300) + "px";
        setTimeout(function () { window.scrollTo(editorWidth + 300, 0); }, 500)
      }
    }

  }, [isFocus])


  const handleOnChangeEvent = (e) => {
    if (document.getElementsByTagName('iframe').length)
      document.getElementsByTagName('iframe')[0].contentDocument.body.addEventListener('blur', function () {
        setData(document.getElementsByTagName('iframe')[0].contentDocument.body.innerHTML)
      });
   
    if (document.getElementsByTagName('iframe').length && document.getElementsByTagName('iframe')[0].contentDocument.body.getElementsByTagName('li').length) {
      let body = document.getElementsByTagName('iframe')[0].contentDocument.body;
      let editorWidth = body.clientWidth;
      let liElements = body.getElementsByTagName('li');
      let totalLength = liElements.length;
      let lastElementWidth = liElements[totalLength - 1].clientWidth;
      if (lastElementWidth < 100) {
        body.style.width = (editorWidth + 300) + "px";
        setTimeout(() => {
          body.getElementsByTagName('li')[totalLength - 1].scrollIntoView();
          window.scrollTo(editorWidth + 300, 0);
        }, 300)
      }
    }

  }


  const saveFileTo = () => {
    setOpenFolderMap(true);
    setShowSaveButton(true);

  }
  const saveDocument = async (e) => {
    console.log('data-->', data);
    let doc = btoa(unescape(encodeURIComponent(data)))

    fetch(`${hostname}/api/create-file?filename=${filenName}`,
      {
        "method": "POST",
        "headers": {
          "Content-Type": "application/json"
        },
        "body": JSON.stringify({ 'doc_data': doc }),
      })
      .then((response) => response.text())
      .then((body) => {
        alert('file saved!!');
        setShowSaveButton(false)
        setOpenFolderMap(false);

      });

  }

  const onFileLoad = (e) => {
    const reader = new FileReader()
    setFileName(e.target.files[0].name)
    reader.readAsArrayBuffer(e.target.files[0])

  }
  useEffect(() => {
    fetch(`${hostname}/api/fetchFiles`)
      .then((response) => response.text())
      .then((body) => {
        let map_data = (JSON.parse(body));
        setMap(map_data)
      });
  }, [])


  const renderData = (file_data) => {
    setData(file_data);
    setOpenFolderMap(false);
    setShowSaveButton(false);
  }


  const onOutsideDrop = (parentId, files) => {
    if (files.length <= 3) {
      let data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append('files', files[i]);
      }
      axios
        .post('http://localhost:8000/upload', data)
        .then(res => {
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  return (


    <>
      <div className='main-wrapper'>
        <div className='header-row'>
          <h2>Custom Editor</h2>
          <div className='btn-wrapper'>
            {!showSaveButton && <input
              type="file"
              id="docpicker"
              onChange={(event) => onFileLoad(event)} />
            }
            {!showSaveButton && <button className='open-file' onClick={() => { setOpenFolderMap(!openFolderMap); setShowSaveButton(false); }} >Open File</button>}
            {!showSaveButton && <button className='save-file' onClick={saveFileTo} >Save File To</button>}
            {showSaveButton && <button className='save-doc' onClick={saveDocument} >click here to Save Document</button>}

          </div>
        </div>

        {!openFolderMap && <CKEditor
        
          editorUrl={"https://cdn.ckeditor.com/4.18.0/full-all/ckeditor.js"}
          initData={data}
          config={{
            toolbar: [

              { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'] },
              { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'], items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote'] },
              { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
              { name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar'] },

              { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
              { name: 'colors', items: ['TextColor', 'BGColor'] },
              { name: 'tools', items: ['Maximize'] },
              { name: 'others', items: ['-'] },
            ],
            toolbarGroups: [
              { name: 'forms' },
              '/',
              
              { name: 'styles' },
              { name: 'colors' },
              { name: 'tools' },
              { name: 'others' }
            ],
            filebrowserUploadUrl: `${hostname}/api/image-upload`,
            filebrowserImageUploadUrl: `${hostname}/api/image-upload`,
            removeDialogTabs: 'image:advanced;link:advanced'
          }}

          onReady={editor => {

          }}
          onChange={(event) => {
            setIsFocus(!isFocus)
            handleOnChangeEvent(event)
          }}
        />
        }
      </div>

      {openFolderMap && map && <div className='fm-popup'>
        <div className='fm-popup-close' onClick={() => setOpenFolderMap(false)}>x</div>

        <FileManagerWrapper
          hostname={hostname}
          map={map}
          rootId="0"
          onChange={map => setMap(map)}
          onOutsideDrop={onOutsideDrop}
          renderData={renderData}
          setFileName={setFileName}
          dropzoneConfig={{
            name: 'files',
            inputProps: {
              type: 'file',
              enctype: 'multipart/form-data',
              action: '/files',
              method: 'post',
            },
          }}
        />
      </div>

      }

    </>
  );

}

export default App;
