import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button, Modal, Tooltip } from 'antd';
import { uniqueId } from 'lodash';
import FileManager, { Types } from './lib';
import EditText from './EditText';
import { title } from 'process';

const ButtonGroup = Button.Group;
const { confirm } = Modal;

const images = {
	file: 'https://raw.githubusercontent.com/exced/react-file-manager/master/public/images/file.png',
	folder: 'https://raw.githubusercontent.com/exced/react-file-manager/master/public/images/folder.png',
};


export default class FileManagerWrapper extends Component {

	constructor(props) {
        super(props);

        this.updatedName = { id: null, title: null};
    }

	deselect = () => {
		this.app.deselect();
	};

	onAddFolder = parentId => {
		const { map, onChange, hostname } = this.props;
		let folder_name = prompt("Please enter folder name", "New Folder");
		const folder = {
			id: `@@${uniqueId()}`,
			title: folder_name,
			type: Types.folder,
			children: [],
			parent: parentId,
			path: map[parentId].path+ "/" + folder_name
		};
		fetch(`${hostname}/api/create-folder/?folderName=${folder_name}&folderPath=${map[parentId].path}/`,
		{ "method": "POST"})
		.then((response) => response.text())
		.then((body) => {
	
		}); 
		console.log('parent id', map[parentId].path)
		onChange({
			...map,
			[parentId]: {
				...map[parentId],
				children: [...map[parentId].children, folder.id],
			},
			[folder.id]: folder,
		});
	};

	onClickRemoveFolder = item => {
		console.log(item);
		if (window.confirm("Remove this folder ?")) {
			this.onRemoveFolder(item.id);
		}
	}
	onClickRenameFolder = item => {
		if (window.confirm("Remove this folder ?")) {
			this.onRenameFolder(item.id);
		}
	}

	removeFolder = (map, id) => {
		const { [id]: folder, ...rest } = map;
		return folder.parent
			? {
					...rest,
					[folder.parent]: {
						...map[folder.parent],
						children: map[folder.parent].children.filter(e => e !== id),
					},
			  }
			: rest;
	};

	cascadeRemoveFolder = (map, id) => {
		const { [id]: folder } = map;
		const c = folder.children.reduce(
			(a, e) => (a[e].type === Types.folder ? this.cascadeRemoveFolder(a, e) : this.removeFile(a, e)),
			map
		);
		return this.removeFolder(c, id);
	};

	onRemoveFolder = id => {
		const { map, onChange, hostname } = this.props;
		this.deselect();
		onChange(this.cascadeRemoveFolder(map, id));
		let folder_name = map[id].path;
		fetch(`${hostname}/api/folder?folderName=${folder_name}`,
		{ "method": "DELETE"})
		.then((response) => response.text())
		.then((body) => {
	
		});
	};

	onChangeFolder = (id, input) => {
		const { map, onChange } = this.props;
		onChange({
			...map,
			[id]: { ...map[id], ...input },
		});

		this.updatedName = { id: id, title: input.title};
	};

	onAddFile = parentId => {
		const { map, onChange, hostname } = this.props;
		let fName = prompt("Please enter file name", "Please don't use .docx in file name");
		let file_name = `${fName}.docx`;
		const file = {
			id: `@@${uniqueId()}`,
			title: file_name,
			type: Types.file,
			children: [],
			parent: parentId,
			path: map[parentId].path+ "/" + file_name
		};
		fetch(`${hostname}/api/create-file?filename=${file_name}&folderPath=${map[parentId].path}/`,
		{ "method": "POST"})
		.then((response) => response.text())
		.then((body) => {
	
		}); 
		onChange({
			...map,
			[parentId]: {
				...map[parentId],
				children: [...map[parentId].children, file.id],
			},
			[file.id]: file,
		});
	};

	saveUpdatedName = () => {
		console.log('saveUpdatedName >>>>', this.updatedName.id, this.updatedName.title);
		this.onRenameFile(this.updatedName.id, this.updatedName.title);
	}



	onRenameFile = (id, new_file_name) => {
		const { map, onChange } = this.props;
		onChange(this.renameFile(map, id, new_file_name));
	};

	handleKeydown = (e, id, name) => {
		if (e.keyCode === 13 || e.charCode === 13) {
			this.onRenameFile(id, name);
		} else if (e.keyCode === 27 || e.charCode === 27) {
			// onBlur();
		}
	};

	renameFile = (map, id, new_file_name) => {
		this.deselect();
		const { hostname } = this.props;
		let temp_map = map;  
		let old_file_name = map[id].path;
		let arr_path = map[id].path.split('/');
		arr_path[arr_path.length - 1] = new_file_name;

		temp_map[id].path = arr_path.join('/');
		temp_map[id].title = new_file_name;
		
		
		fetch(`${hostname}/api/file-folder-rename?oldName=${old_file_name}&newName=${temp_map[id].path}`,
		{ "method": "PUT"})
		.then((response) => response.text())
		.then((body) => {

		});
		return temp_map;
	};

	onClickRemoveFile = item => {
		console.log(item);
		if (window.confirm("Remove this file ?")) {
			this.onRemoveFile(item.id)
		}
	}

	removeFile = (map, id) => {
		const { hostname } = this.props;
		const { [id]: file, ...rest } = map;
		let file_name = map[id].path;
		fetch(`${hostname}/api/file?filename=${file_name}`,
		{ "method": "DELETE"})
		.then((response) => response.text())
		.then((body) => {
		});
		return file.parent
			? {
					...rest,
					[file.parent]: {
						...map[file.parent],
						children: map[file.parent].children.filter(e => e !== id),
					},
			  }
			: rest;
	};

	onRemoveFile = id => {
		const { map, onChange } = this.props;
		this.deselect();
		onChange(this.removeFile(map, id));
	};

	onChangeFile = (id, input) => {
		console.log('onChangeFile >>>>>');
		const { map, onChange } = this.props;
		onChange({
			...map,
			[id]: { ...map[id], ...input },
		});
		this.updatedName = { id: id, title: input.title};
	};
	  

	renderItem = (item, index) => (
		<div className='folder-button-wrapper'>
			<div className='folder-icon'>
				<img
					src={item.type === Types.folder ? images.folder : images.file}
					alt={item.title}
					style={{ width: 22, height: 22, float: 'left' }}
				/>
			</div>
			<div className='folder-text' style={{ flex: 5 }}>
				<span
					style={{
						width: 150,
						textAlign: 'left',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
					}}
				>
					{item.title}
				</span>
			</div>
			<div className='folder-arrow'>
				{item.type === Types.folder && <Icon type="right" />}
			</div>
		</div>
	);

	renderPreview = (item, index) => {
		const { rootId } = this.props;
		// folder
		console.log('item >>',item)
		if (item.type === Types.folder) {
			if (item.id === rootId) {
				return (
					<div
						style={{
							textAlign: 'center',
							margin: 'auto',
							marginTop: 170,
							width: 200,
							height: 200,
							border: '1px solid',
							borderRadius: 6,
							borderColor: '#ccc',
						}}
					>
						<img
							src={images.folder}
							alt={item.title}
							style={{ width: 70, height: 70, margin: 'auto', display: 'block', marginTop: 40 }}
						/>
						<span>{item.title}</span>
						<ButtonGroup>
							<Tooltip title="New folder">
								<Button onClick={() => this.onAddFolder(item.id)} icon="folder-add" />
							</Tooltip>
							<Tooltip title="New file">
								<Button onClick={() => this.onAddFile(item.id)} icon="file-add" />
							</Tooltip>
						</ButtonGroup>
					</div>
				);
			}
			return (
				<div
					style={{
						textAlign: 'center',
						margin: 'auto',
						marginTop: 170,
						width: 200,
						height: 200,
						border: '1px solid',
						borderRadius: 6,
						borderColor: '#ccc',
					}}
				>
					<img
						src={images.folder}
						alt={item.title}
						style={{ width: 70, height: 70, margin: 'auto', display: 'block', marginTop: 10 }}
					/>
					<EditText
						value={item.title}
						onChange={title => this.onChangeFolder(item.id, { title })}
						size="small"
						placeholder="Title"
					/>
					{this.updatedName.title?.length > 0 && <Button className='save-btn' onClick={() => this.saveUpdatedName()}>Update Name</Button>}
					<div style={{ marginTop: 10 }}>
						<ButtonGroup>
							<Tooltip title="New folder">
								<Button onClick={() => this.onAddFolder(item.id)} icon="folder-add" />
							</Tooltip>
							<Tooltip title="New file">
								<Button onClick={() => this.onAddFile(item.id)} icon="file-add" />
							</Tooltip>
							<Tooltip title="Remove folder">
								<Button onClick={() => this.onClickRemoveFolder(item)} type="danger" icon="delete" />
							</Tooltip>
						</ButtonGroup>
					</div>
				</div>
			);
		}
		// file
		return (
			<div
				style={{
					textAlign: 'center',
					margin: 'auto',
					marginTop: 170,
					width: 200,
					height: 200,
					border: '1px solid',
					borderRadius: 6,
					borderColor: '#ccc',
				}}
			>
				<img
					src={images.file}
					alt={item.title}
					style={{ width: 70, height: 70, margin: 'auto', display: 'block', marginTop: 40 }}
				/>
				<EditText
					value={item.title}
					onChange={title => this.onChangeFile(item.id, { title })}
					size="small"
					placeholder="Title"
				/>
				{this.updatedName.title?.length > 0 && <Button className='save-btn' onClick={() => this.saveUpdatedName()}>Update Name</Button>}
				<div style={{ marginTop: 10 }}>
					<ButtonGroup>
						<Tooltip title="Remove file">
							<Button onClick={() => this.onClickRemoveFile(item)} type="danger" icon="delete" />
						</Tooltip>
					</ButtonGroup>
				</div>
			</div>
		);
	};

	render() {
		const { map, rootId, onChange } = this.props;
		return (
			<FileManager
				ref={ref => (this.app = ref)}
				map={map}
				rootId={rootId}
				onChange={onChange}
				onChangeRow={this.props.onChangeRow}
				onChangeColumn={this.props.onChangeColumn}
				onOutsideDrop={this.props.onOutsideDrop}
				dropzoneConfig={this.props.dropzoneConfig}
				renderItem={this.props.renderItem ? this.props.renderItem : this.renderItem}
				renderPreview={this.props.renderPreview ? this.props.renderPreview : this.renderPreview}
				itemSelectedColor={this.props.itemSelectedColor}
				dropBackgroundColor={this.props.dropBackgroundColor}
				renderData={this.props.renderData}
				setFileName={this.props.setFileName}
			/>
		);
	}
}

FileManagerWrapper.propTypes = {
	map: PropTypes.object.isRequired,
	rootId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func.isRequired,
	onChangeRow: PropTypes.func,
	onChangeColumn: PropTypes.func,
};

FileManagerWrapper.defaultProps = {
	onChangeRow: (id, src, dest) => {},
	onChangeColumn: (id, src, dest) => {},
	onOutsideDrop: (id, files) => {},
	itemSelectedColor: '#1a53ff',
	dropBackgroundColor: '#cccdce',
	dropzoneConfig: {},
};
